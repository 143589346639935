<template>
  <div class="container">
    <TheHeader />
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 my-4 my-md-5">
            <a-steps :current="stepActive" :status="($store.getters['register/isError'] === true) ? 'error' : 'process'">
              <a-step title="Publication" />
              <a-step title="Administrator" />
              <a-step title="Confirmation" />
            </a-steps>
          </div>
        </div>
        <div v-if="stepActive === 0" class="row justify-content-center">
          <div class="col-12 col-md-5 col-lg-4 mb-5">
            <h1>Let’s get started!</h1>
            <p>On Postodian, everything happens within a publication. You can write, publish and schedule your articles.</p>
          </div>
          <div class="col-12 col-md-5 col-lg-4">
            <RegisterStep1 @success="stepActive = 1" />
          </div>
        </div>
        <div v-if="stepActive === 1" class="row justify-content-center">
          <div class="col-12 col-md-5 col-lg-4 mb-5">
            <h1>Become the admin</h1>
            <p>You will be the owner of {{ $store.state.register.data.publicationName }}. You can invite collaborators or assign the ownership to someone else later on.</p>
            <p><strong>{{ $store.state.register.data.subdomain }}.postodian.com</strong></p>
          </div>
          <div class="col-12 col-md-5 col-lg-4">
            <RegisterStep2 @success="stepActive = 2" @cancel="stepActive = 0" />
          </div>
        </div>
        <div v-if="stepActive === 2" class="row justify-content-center">
          <RegisterStep3 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import RegisterStep1 from '@/components/Register/RegisterStep1'
import RegisterStep2 from '@/components/Register/RegisterStep2'
import RegisterStep3 from '@/components/Register/RegisterStep3'

export default {
  components: {
    TheHeader,
    RegisterStep1,
    RegisterStep2,
    RegisterStep3,
  },

  data() {
    return {
      stepActive: 0,
    }
  },
}
</script>
