<template>
  <a-form
    :colon="false"
    layout="vertical"
    :model="values"
    ref="form"
    @finish="onFinish"
  >
    <a-form-item
      label="Name of Publication"
      name="publicationName"
      :rules="[
        { required: true, message: 'Name is required.' },
      ]"
    >
      <a-input
        v-model:value="values.publicationName"
        :maxlength="32"
        name="publicationName"
      />
    </a-form-item>
    <a-form-item
      label="Subdomain"
      name="subdomain"
      :rules="[
        { required: true, message: 'Subdomain is required.' },
        { min: 5, message: 'Subdomain must be at least 5 characters.' },
        { validator: checkSubdomain },
      ]"
    >
      <a-input
        v-model:value="values.subdomain"
        addon-after=".postodian.com"
        :maxlength="30"
        name="subdomain"
      />
    </a-form-item>
    <a-form-item
      label="Default Language"
      name="language"
      :rules="[
        { required: true, message: 'Language is required.' },
      ]"
    >
      <a-select
        v-model:value="values.language"
        showSearch
        placeholder="Select a language"
      >
        <a-select-option v-for="(name, value) in $store.state.languages" :value="value">
          {{ name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-button type="primary" html-type="submit" :loading="loading">
      Continue
    </a-button>
    <a-button type="link" @click="$router.push({ name: 'login' })">
      I have an account
    </a-button>
  </a-form>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      values: {
        publicationName: this.$store.state.register.data.publicationName,
        subdomain: this.$store.state.register.data.subdomain,
        language: this.$store.state.register.data.language || window.navigator.language.slice(0,2),
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        this.$store.commit('register/isError', false)
        this.$store.commit('register/field', { publicationName: values.publicationName }, { root: true })
        this.$store.commit('register/field', { subdomain: values.subdomain }, { root: true })
        this.$store.commit('register/field', { language: values.language }, { root: true })

        this.$emit('success', values)
      } catch (err) {
        this.$store.commit('register/isError', true)
      }
    },
    async checkSubdomain() {
      try {
        var subdomain = this.values.subdomain && this.values.subdomain.trim()

        if (!subdomain || subdomain.length < 5 || subdomain.length > 30) {
          return false
        }

        var { data } = await this.$http.get(`/subdomains/${ subdomain }`)

        if (!data.available) {
          return Promise.reject('Subdomain is not available.')
        }
      } catch (err) {
        return Promise.reject('Couldn’t check availability.')
      }
    },
  },
}
</script>
