<template>
  <a-form
    :colon="false"
    layout="vertical"
    :model="values"
    ref="form"
    @finish="onFinish"
  >
    <a-form-item
      label="Full Name"
      name="name"
      :rules="[
        { required: true, message: 'Name is required.' },
      ]"
    >
      <a-input
        v-model:value="values.name"
        name="name"
      />
    </a-form-item>
    <a-form-item
      label="Email Address"
      name="email"
      :rules="[
        { required: true, message: 'Email address is required.' },
        { type: 'email', message: 'Email address is invalid.' },
      ]"
    >
      <a-input
        v-model:value="values.email"
        name="email"
        placeholder="name@company.com"
        type="email"
      />
    </a-form-item>
    <a-form-item
      label="Password"
      name="password"
      :rules="[
        { required: true, message: 'Password is required.' },
        { min: 6, message: 'Password length must be at least 6.' },
      ]"
    >
      <a-input
        v-model:value="values.password"
        name="password"
        type="password"
      />
    </a-form-item>
    <a-form-item
      label="Confirm Password"
      name="passwordConfirm"
      :rules="[
        { required: true, message: 'Confirmation password is required.' },
        { min: 6, message: 'Password length must be at least 6.' },
      ]"
    >
      <a-input
        v-model:value="values.passwordConfirm"
        name="passwordConfirm"
        type="password"
      />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" :loading="loading">
        New Publication
      </a-button>
      <a-button type="link" @click="$emit('cancel')">
        Go back
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      values: {
        name: this.$store.state.register.data.name,
        email: this.$store.state.register.data.email,
        password: null,
        passwordConfirm: null,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        this.$store.commit('register/isError', false)
        this.$store.commit('register/field', { name: values.name }, { root: true })
        this.$store.commit('register/field', { email: values.email }, { root: true })
        this.$store.commit('register/field', { password: values.password }, { root: true })
        this.$store.commit('register/field', { passwordConfirm: values.passwordConfirm }, { root: true })

        var { data } = await this.$http.post('/me', this.$store.state.register.data)

        this.$emit('success', data)
      } catch (err) {
        this.$store.commit('register/isError', true)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
